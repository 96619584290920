.App {
  text-align: center;
  min-height: 100vh;
  background-color: #f7931a; /* Bitcoin's orange color */
  font-family: 'Comic Sans MS', 'Comic Sans', sans-serif ; /* Comic Sans for a playful look */
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

h4 {
  margin-top: 0;
  font-size: 1.5rem;
  max-width: 400px;
  color: #333399; /* Dark blue color */
  text-shadow: 4px 4px 0px #ffa500; /* Orange shadow for fun */
  transform: rotate(10deg); /* Slight rotation for the text */
}

p {
  font-size: 1.5rem;
  color: #333399; /* Dark blue color */
  text-shadow: 4px 4px 0px #ffa500; /* Orange shadow for fun */
  transform: rotate(-10deg); /* Slight rotation for the text */
}

footer {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #006400; /* Dark green color */
  font-weight: bold;
}

/* Keyframes for rotation */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.reason {
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
  font-size: 1.5rem;
  color: #ff4500; /* Or any funny color */
}

button {
  margin-top: 20px;
  padding: 15px 30px;
  font-size: 1.2rem;
  color: #000; /* Black text for contrast */
  background-color: #f2a900; /* Bitcoin gold */
  border: 2px solid #000; /* Solid black border for a sleek look */
  border-radius: 10px; /* Rounded corners for a modern touch */
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  font-family: 'Arial', sans-serif; /* Modern, clean font */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

button:hover {
  background-color: #e5b000; /* A slightly lighter shade of gold */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* More pronounced shadow */
  transform: translateY(-2px); /* Slight lift effect */
}

button:active {
  background-color: #c99700; /* Darker gold on click */
  transform: translateY(2px); /* Push-down effect */
}



@keyframes wiggle {
  0%, 100% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

button:hover {
  animation: wiggle 0.2s ease-in-out infinite;
}

img {
  width: 50%; /* Adjust the size as needed */
  height: auto;
  margin-top: 20px;
  border-radius: 10px; /* Rounded corners for a softer look */
  transform: rotate(-5deg); /* Slight rotation for a playful effect */
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  transition: transform 0.3s ease-in-out;
  max-width: 200px;
}

img:hover {
  transform: rotate(5deg); /* Rotate in the opposite direction on hover */
}
